<template>
  <div class="sku">
    <div
      class="guigeBox flex"
      style="margin-right:44px;width: 100%;flex-wrap: wrap;"
    >
      <div
        class="algeGuigeleft"
        style="flex-wrap: wrap;display: inline-block;"
        v-if="new_goods_specs_list&&new_goods_specs_list.length"
      >
        <!-- 添加规格名 规格值 -->
        <div
          class="goods_specx_add"
          style="width:300px;margin-right:40px;margin-bottom:12px;"
          v-for="(it,idx) in new_goods_specs_list"
          :key="idx+'k'"
        >
          <p
            class="flex align-item-center"
            style="font-size: 14px; font-weight: 500;color: #333333;"
          >
            {{ new_goods_specs_list[idx].attr_name }}
            <!-- <a-icon
              style="margin-left:6px;display:inline-block;width: 24px;height: 24px;line-height:28px;background: #F0F5F4;border-radius: 4px;color:#819190;"
              type="delete"
              class="pointer"
              @click="deleteNewspecs( idx,it )"
            ></a-icon> -->
            <i
              class="meiye-icon meiye-shanchu pointer"
              style="margin-left:6px;text-align:center;display:inline-block;width: 24px;height: 24px;line-height:24px;border-radius: 4px;color:#819190;"
              type="delete"
              @click="deleteNewspecs( idx,it )"
            ></i>
          </p>
          <div
            class="flex align-item-center"
            style="flex-wrap:wrap;"
          >
            <span
              class="mgb-12"
              style="font-size: 14px; font-weight: 400;color: #9EA6A4;margin-right:6px;"
            >规格值:</span>
            <div
              class="mgr-24 flex mgb-12 tagsValue"
              v-for="(m,n) in new_goods_specs_list[idx].attr_values"
              :key="n+'l'"
            >
              <div class="flex tagsValueTxt">{{ new_goods_specs_list[idx].attr_values[n] }}</div>

              <i
                class="meiye-icon meiye-close-bold pointer"
                @click="deletenewGoodsSpecs(idx,n)"
              ></i>
            </div>
          </div>
          <div class="guigeline"></div>
        </div>

      </div>
      <div
        class="guigeBox_rgt mgb-12"
        v-if="isShowGuigeAddSelect"
      >
        <div
          class="flex "
          style="flex-warp:nowrap;"
        >
          <a-select
            allow-clear
            class="pgb-20 selectGg"
            placeholder="选择规格"
            v-model="goods_specs.goods_specs_id"
            style="width: 180px;margin-right:20px;"
            @change="changeGoodsSpecs"
            dropdownClassName="selectGg"
          >
            <a-select-option
              v-for="it in goods_specs.goods_specs_list"
              :key="it.id"
              :value="it.id"
            >
              <i
                class="meiye-icon meiye-routeicon-add-copy baseColor11"
                v-if="it.id==9999"
              ></i>

              {{ it.attr_name }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            :disabled="surebtndisabled"
            @click="changeGoodsSpecs1"
          > 确认</a-button>

        </div>
        <!-- 规格 列 -->
        <div
          class="guige_values flex align-item-center"
          v-if="ischangeGoodsSpecs1"
          style="margin-top:18px;"
        >
          <!-- <div
            class="guige_values_options flex  align-item-center"
            v-for="(item, index) in goods_specs.goods_specs_values_list"
            :key="index"
            style="padding:2px 8px;background: #F3FAFA;margin-right:16px; "
          >
            <div
              class="flex baseColor11"
              style=" margin:0 8px 0 0"
              v-if="goods_specs.goods_specs_values_list[index]"
            >
              {{goods_specs.goods_specs_values_list[index] }}
            </div>
            <div
              v-else
              class="flex htmlboxshops"
              style="width:68px;height:24px;border:none;box-shadow:none;outline:none;overflow:hidden;white-space: Nowrap; "
              contenteditable
              v-html="goods_specs.goods_specs_values_list[index]"
              @input="onqyChange($event,item,index)"
            >
            </div>
            <i
              class="meiye-icon meiye-close-bold"
              @click="closegoodsSpecsValues(index)"
            ></i>
          </div>
          <span
            class="pointer"
            @click="legalObjadd()"
            style="display:inline-block;padding:2px 8px; background: #F8F8F8;color: #768483;"
          >添加值 + </span> -->
          <div class="editTags">
            <template v-for="(tag,index) in  goods_specs.goods_specs_values_list">
              <a-tooltip
                v-if="tag.length > 20"
                :key="tag"
                :title="tag"
              >
                <a-tag
                  :key="tag"
                  closable
                  @close="() => closegoodsSpecsValues(index)"
                >
                  {{ `${tag.slice(0, 20)}...` }}
                </a-tag>
              </a-tooltip>
              <a-tag
                v-else
                :key="tag"
                closable
                @close="() => closegoodsSpecsValues(index)"
              >
                {{ tag }}
              </a-tag>
            </template>
            <!--  -->
            <span
              v-if="inputVisible2"
              style="display:inline-block;height:24px;line-height:24px;"
            >
              <a-input
                ref="input2"
                type="text"
                size="small"
                class="ant-input_1"
                :style="{ width: '78px' }"
                :value="inputValue2"
                @change="($event)=>onqyChange($event,index)"
                @blur="($event)=>handleInputConfirm2($event,index)"
                @keyup.enter="($event)=>handleInputConfirm2($event,index)"
              />
            </span>
            <!--   -->
            <a-tag
              v-if="!inputVisible2 && goods_specs.goods_specs_values_list.length"
              style="background: #fff; borderStyle: dashed;  "
              @click="legalObjadd"
            >
              添加值 <a-icon type="plus" />
            </a-tag>
          </div>
        </div>
      </div>
      <!-- 新增规格 -->
      <div
        class="addGuigeRules"
        style="display:inline-block;"
        v-if="!isShowGuigeAddSelect&&new_goods_specs_list&&new_goods_specs_list.length"
      >
        <span
          class="pointer"
          @click=";isShowGuigeAddSelect=true;"
        > <i class="meiye-icon meiye-routeicon-add"></i>
          添加规格</span>
      </div>
    </div>

    <a-modal
      title="新增规格"
      :visible="showaddGoodsprivatespec"
      @cancel=";qyStep = 2;showaddGoodsprivatespec=false; singelguigeadd.attr_name=undefined;singelguigeadd.attr_values=[]"
      @ok="addguigeokFn"
      :width="600"
      class="mrj_modal_normal "
    >
      <div>
        <a-form
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-item
            required
            label="规格名称"
            style="margin-bottom:22px;"
          >
            <a-input
              style="min-width: 412px;"
              class="ant-input_1"
              v-model="singelguigeadd.attr_name"
            ></a-input>
          </a-form-item>
          <a-form-item
            label="规格值"
            required
          >
            <!-- <div
              class="mgr-24 flex"
              style=" display: inline-block;position:relative; padding:2px 0px; margin-right:16px;"
              v-for="(m,n) in singelguigeadd.attr_values"
              :key="n+'u'"
            >
              <a-input
                style="width:95px;height:25px;border:none;box-shadow:none;background: #F3FAFA;color:#00B2A8 !important;"
                v-model="singelguigeadd.attr_values[n]"
              />
            </div>
            <span
              class="pointer"
              style="display:inline-block;border-radius: 4px;padding:0px 8px;height: 25px;line-height: 25px; background: #F8F8F8;color: #768483;"
              @click="addnewgoodsspecs1()"
            >添加值 + </span> -->

            <div>
              <template v-for="(tag) in  singelguigeadd.attr_values">
                <a-tooltip
                  v-if="tag.length > 20"
                  :key="tag"
                  :title="tag"
                >
                  <a-tag
                    :key="tag"
                    closable
                    class="tagsValue"
                    @close="() => handleClose(tag)"
                  >
                    {{ `${tag.slice(0, 20)}...` }}
                  </a-tag>
                </a-tooltip>
                <a-tag
                  v-else
                  :key="tag"
                  class="tagsValue"
                  closable
                  @close="() => handleClose(tag)"
                >
                  {{ tag }}
                </a-tag>
              </template>
              <!--  -->
              <span
                v-if="inputVisible"
                style="display:inline-block;height:24px;line-height:24px;"
              >
                <a-input
                  ref="input"
                  type="text"
                  size="small"
                  class="ant-input_1"
                  :style="{ width: '78px' }"
                  :value="inputValue"
                  @change="handleInputChange"
                  @blur="handleInputConfirm"
                  @keyup.enter="handleInputConfirm"
                />
              </span>
              <!--   -->
              <a-tag
                v-else
                style="background: #fff; borderStyle: dashed;  "
                @click="showInput"
              >
                添加值 <a-icon type="plus" />
              </a-tag>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </div>
  </div>

</template>

<script> 
import moment from 'moment';
import { GetmanageGoodslist, GetgoodsoptionsList, CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList } from '@/api/commodity'

import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
export default {
  inject: ['reload'],
  components: {},
  props: {
    new_goods_specs_list: {
      type: Array,
      default: () => []
    },
    goods_specs_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      brandsList: [],//品牌列表
      typesList: [
        { name: '客装', id: 1 },
        { name: '院装', id: 2 },
      ],//类别
      isactive: false,
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置商品码
        price: undefined,//批量设置单价
        product_image: undefined,//批量设置图片
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list1: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        }
      },
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      // 表单头
      goods_specs_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '*标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '商品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ],
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      // 固定的规格和规格值
      forevergoodSpecsObj: {
        activegoodSpecs: undefined,
        forevergoodSpecsList: [
          { name: '部位', id: '1', children: [{ name: '全身', id: '2' }, { name: '背部', id: '3' }, { name: '面部', id: '4' }] },
          { name: '颜色', id: '5', children: [{ name: '红色', id: '6' }, { name: '白色', id: '7' }, { name: '黑色', id: '8' }] },
          { name: '尺寸', id: '9', children: [{ name: '大', id: '10' }, { name: '中', id: '11' }, { name: '小', id: '12' }] },
          { name: '新建规格', id: '9999' }
        ],
        // 
      },
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      isShowGuigeAddSelect: true,
      form: {//表单数据
        goods_id: 1,
        goods_name: 1,
        card_sell_type: 1,//1长期 截止2
        guige_status: 1,//规格  单规格1 多规格2
        skuTable: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: '',
        goods_image: '',
        goods_slider: '',
        goods_type: 0,
        goods_supplier: 1,
        goods_brand: 0,

        goods_category: '',
        goods_unit: '',
        is_show: '',
        is_del: '',
        goods_content: '',
        goods_attr: [],
        goods_attr_val: [],
        goods_shou_type: '',
        goods_shou_end_time: ''
      },
      step: 1,
      imgflag: false,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: undefined,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: undefined,//轮播图
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      inputVisible: false,
      inputValue: undefined,
      surebtndisabled: false,//确认按钮点击状态
      inputValue2: undefined,
      inputVisible2: false,

    };
  },
  created() {

  },
  methods: {
    moment,

    // 单规格 上传规格图
    async uploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goodSpecsSingletableData[0].product_image = res.data.ur
      } else {
        this.$message.error(res.error.message)
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    // 选择规格
    changeGoodsSpecs(e, i) {
      let self = this

      if (e == 9999) {
        self.goods_specs.goods_specs_id = undefined
        self.showaddGoodsprivatespec = true //自定义新增规格弹框
        self.ischangeGoodsSpecs1 = false
        return false
      }
      self.goods_specs.goods_specs_list.forEach(el => {
        if (el.id == e) {
          if (!el.attr_values) {
            self.surebtndisabled = true
          } else {
            self.surebtndisabled = false
          }
          self.goods_specs.goods_specs_values_list = [...el.attr_values]
          self.goods_specs.goods_specs_selectobj = Object.assign({}, el)
        }
      })
      // self.goods_specs.goods_specs_list1.forEach(el => {
      //   if (el.id == e) {
      //     
      //     self.goods_specs.goods_specs_values_list = [...el.attr_values]
      //     //  if(!el.attr_values){
      //     //   self.surebtndisabled = true
      //     //  }else{
      //     //   self.surebtndisabled = false
      //     //  }
      //     // self.goods_specs.new_goods_specs_list.push(el)
      //     self.goods_specs.goods_specs_selectobj = Object.assign({}, el)
      //   }
      // })
      self.ischangeGoodsSpecs1 = true
      console.log(self.goods_specs.goods_specs_values_list, "goods_specs_values_list");
      // console.log(self.goods_specs.goods_specs_keys_list, " self.goods_specs.goods_specs_keys_list");

    },
    // 确认规格
    changeGoodsSpecs1() {
      // 确认没有空的规格值  然后push   
      // todo 生成sku表这里需要$emit(' 父级 生成值的运算') 
      if (this.goods_specs.goods_specs_id === 0) {

      } else if (!this.goods_specs.goods_specs_id) {
        return this.$message.error('请选择或自定义规格')
      }
      console.log();
      let flag = false
      let self = this
      this.goods_specs.goods_specs_values_list.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后进入下一步。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
            // self.qystep = 2
          },
        });
        return false
      }
      console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      console.log(this.goods_specs.goods_specs_selectobj);
      this.goods_specs.goods_specs_selectobj.attr_values = this.goods_specs.goods_specs_values_list
      this.new_goods_specs_list.push(this.goods_specs.goods_specs_selectobj)
      this.goods_specs.goods_specs_list.forEach((el, idx) => {
        if (el.id == this.goods_specs.goods_specs_selectobj.id) {
          this.goods_specs.goods_specs_list.splice(idx, 1)
          this.goods_specs.goods_specs_id = undefined

        }
      })
      console.log(this.goods_specs.goods_specs_list);
      // 生成多规格列表
      // this.hideModal() 
      this.$emit('getSpecArr', this.new_goods_specs_list)
      this.ischangeGoodsSpecs1 = false
      this.isShowGuigeAddSelect = false
    },
    // 
    onqyChange(e, idex) {
      let self = this
      self.inputValue2 = e.target.value;
      // let a = self.goods_specs.goods_specs_values_list[idex]
      // console.log(e, self.goods_specs.goods_specs_values_list[idex]);
      // console.log(this.goods_specs.goods_specs_values_list);

      // self.goods_specs.goods_specs_values_list[idex] = e.target.value
    },
    handleInputConfirm2(e, index) {
      const inputValue = this.inputValue2;
      let tags = this.goods_specs.goods_specs_values_list;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(this, {
        inputVisible2: false,
        inputValue2: '',
      });
      this.goods_specs.goods_specs_values_list = tags

    },
    // closegoodsSpecsValues(e) {
    //   this.goods_specs.goods_specs_values_list.splice(e, 1)
    //   console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
    // },
    legalObjadd() {
      let flag = false
      let self = this
      this.inputVisible2 = true;
      this.$nextTick(function () {
        this.$refs.input2.focus();
      });
      // this.goods_specs.goods_specs_values_list.forEach(el => {
      //   if (!el) {
      //     flag = true
      //   }
      // })
      // if (flag && self.goods_specs.goods_specs_values_list.length > 0) {
      //   this.$warning({
      //     title: '提示',
      //     content: h => {
      //       return h('div', [
      //         h(
      //           'span',
      //           [' 信息尚未填写，请完善内容后进入下一步。']
      //         ),
      //       ])
      //     },

      //     okText: '好的',
      //     okType: 'primary',
      //     // cancelText: '取消',
      //     onOk() {
      //       // self.qystep = 2
      //     },
      //   });
      //   return false
      // }
      // console.log(this.goods_specs.goods_specs_values_list);
      // this.goods_specs.goods_specs_values_list.push('')
      // this.surebtndisabled = false
    },
    // 删除新增的规格名 规格值
    deleteNewspecs(e, it) {
      let self = this
      // delete this.goods_specs.new_goods_specs_list[e]
      this.new_goods_specs_list.splice(e, 1)
      // 恢复 goods_specs_list 的值
      this.goods_specs.goods_specs_list0.forEach((el, idex) => {
        if (el.attr_name == it.attr_name) {
          self.goods_specs.goods_specs_list.unshift(el)
          // self.goods_specs.goods_specs_list.splice(el,idex)

        }
      })
      console.log(self.goods_specs.goods_specs_list, "self.goods_specs.goods_specs_list");

      // 重新 生成多规格列表
      if (this.new_goods_specs_list.length < 1) {
        this.isShowGuigeAddSelect = true
      } else {
        this.isShowGuigeAddSelect = false
      }
      // this.hideModal()
      this.$emit('getSpecArr', this.new_goods_specs_list)
      console.log(this.new_goods_specs_list);
    },
    closegoodsSpecsValues(e) {
      // 此处 attrvalue值不需要改变  goods_specs.goods_specs_values_list0 原始指针改变
      this.goods_specs.goods_specs_values_list.splice(e, 1)
      if (this.goods_specs.goods_specs_values_list.length < 1) {
        this.surebtndisabled = true
      }
      console.log(this.goods_specs.goods_specs_values_list, "this.goods_specs.goods_specs_values_list");
      // console.log(this.goods_specs.goods_specs_values_list0, "this.goods_specs.goods_specs_values_list0");
    },
    // 删除某一个attrvalue值
    deletenewGoodsSpecs(idx, n) {
      // 调取 如果  
      let obj = {}
      this.goods_specs.goods_specs_list0.forEach((el, index) => {
        if (el.attr_name == this.new_goods_specs_list[idx].attr_name) {
          obj = Object.assign({}, el)
        }
      })
      console.log(this.goods_specs.goods_specs_list0, "this.goods_specs.goods_specs_values_list0");

      this.new_goods_specs_list[idx].attr_values.splice(n, 1)
      if (this.new_goods_specs_list[idx].attr_values.length == 0) {
        this.new_goods_specs_list.splice(idx, 1)
        if (obj) this.goods_specs.goods_specs_list.unshift(obj)
      }
      // // console.log(this.goods_specs.new_goods_specs_list[idx].attr_values);
      // 选择框 加入 删除的选项 
      // this.hideModal()
      if (this.new_goods_specs_list.length < 1) {
        this.isShowGuigeAddSelect = true
        this.surebtndisabled = true
        // this.goods_specs.goods_specs_list = this.goods_specs.goods_specs_list0
      } else {
        this.surebtndisabled = false
      }
      console.log(this.goods_specs.goods_specs_list0, "this.goods_specs.goods_specs_values_list0");
      this.$emit('getSpecArr', this.new_goods_specs_list)
    },
    // 批量生成规格表格  设置 单价 和 商品码
    createdSpecsTable() {
      let self = this
      this.goodSpecstableData.forEach((el, idx) => {
        self.goodSpecstableData[idx].product_price = self.goods_specs.price
        self.goodSpecstableData[idx].product_bn = self.goods_specs.barcode
        self.goodSpecstableData[idx].product_image = self.goods_specs.product_image

      })
      //   
      console.log(this.goodSpecstableData, "vvvthis.goodSpecstableData");
    },
    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, flag) {
      console.log(record, i, flag, "val,record");
      //   
      console.log(this.goodSpecstableData, 'goodSpecstableData')
      if (flag) {
        this.goodSpecsSingletableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      } else {
        this.goodSpecstableData.forEach((el, idx) => {
          if (el.product_id == record.product_id || el.product_id_add == record.product_id_add) {
            this.goodSpecsSingletableData[idx][i] = record[i]
          }
        })
      }

    },

    addnewgoodsspecs(idx, n) {
      let flag = false
      let self = this
      this.new_goods_specs_list[idx].attr_values.forEach(el => {
        if (!el) {
          flag = true
        }
      })
      if (flag && self.new_goods_specs_list[idx].attr_values.length > 0) {
        this.$warning({
          title: '提示',
          content: h => {
            return h('div', [
              h(
                'span',
                [' 信息尚未填写，请完善内容后操作。']
              ),
            ])
          },

          okText: '好的',
          okType: 'primary',
          // cancelText: '取消',
          onOk() {
          },
        });
        return false
      }
      console.log(this.new_goods_specs_list[idx].attr_values);
      this.new_goods_specs_list[idx].attr_values.push('')
    },
    addnewgoodsspecs1() {
      let flag = false
      let self = this
      if (this.singelguigeadd.attr_values.length) {
        this.singelguigeadd.attr_values.forEach(el => {
          if (!el) {
            flag = true
          }
        })
        if (flag && self.singelguigeadd.attr_values.length > 0) {
          this.$warning({
            title: '提示',
            content: h => {
              return h('div', [
                h(
                  'span',
                  [' 信息尚未填写，请完善内容后操作。']
                ),
              ])
            },

            okText: '好的',
            okType: 'primary',
            // cancelText: '取消',
            onOk() {
            },
          });
          return false
        }
      } else {
        // this.singelguigeadd[idx].attr_values.push('')
      }

      console.log(this.singelguigeadd.attr_values);
      this.singelguigeadd.attr_values.push('')
    },
    addguigeokFn(e) {
      console.log(e, 'addguigeokFn');
      console.log(this.singelguigeadd, 'singelguigeadd');
      if (!this.singelguigeadd.attr_name) {
        return this.$message.error('请填写规格')
      }
      if (!this.singelguigeadd.attr_values.length) {
        return this.$message.error('请填写规格值')
      }
      this.new_goods_specs_list.push(this.singelguigeadd)
      this.singelguigeadd = {
        attr_name: '',
        attr_values: [],
      }
      // this.hideModal()
      this.$emit('getSpecArr', this.new_goods_specs_list)
      this.showaddGoodsprivatespec = false
      this.isShowGuigeAddSelect = false
    },
    // 生成多规格table
    hideModal() {
      console.log('往表格里面加规格列表');
      var arr = []
      var arr_goods_attr = []
      this.new_goods_specs_list
      this.goods_specs.goods_specs_list
      //   
      arr = [...this.new_goods_specs_list]
      // todo 以后这里需要把规格列表传给后台 成功之后 1，2
      // 1 在这里就需要渲染table的数据和columns操作
      let arri1 = []
      // coluns
      let columns = []
      let specs_clomns = [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          title: '标准单价',
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },
        {
          title: '商品码',
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
      ]
      arr.forEach((el, index) => {
        let object1 = {
          attr_name: el.attr_name,
          attr_values: el.attr_values,
        }
        let object2 = {
          title: el.attr_name,
          dataIndex: el.attr_name,
          key: el.attr_name,
          ellipsis: true,
          scopedSlots: { customRender: el.attr_name },
        }
        arri1.push(object1)
        columns.push(object2)
      })
      this.permissionInfo.info.goods_attr = arri1 //规格
      // 动态生成表头
      this.goods_specs_clomns = [...columns, ...specs_clomns]

      //  
      let newResult2 = []
      let newResult = arri1.reduce((a, b, c) => {
        let res = []
        a.map(x => {
          b.attr_values.map(y => {
            res.push({ ...x, [b.attr_name]: y })
          })
        })
        return res
      }, [{}])
      // newResult2 = Object.assign(newResult2, newResult)
      console.log(newResult, 1, newResult2)
      // 动态生成 商品规格表

      // 编辑条件下 比较与旧版的商品规格表 把数据贴上去
      if (this.addorupdateSpecFlag == 'update') {
        newResult.forEach((el, idx) => {
          this.permissionInfo.info.goods_attr_val.forEach((m, n) => {
            //   
            let reg = /\\"/g;
            let a = JSON.stringify(m.product_attr).replace(reg, '');
            console.log(JSON.stringify(el) == a)
            if (JSON.stringify(el) == a) {
              newResult[idx] = Object.assign(m, el)
              // newResult[idx] = m
            }

          })

        })

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        console.log(newResult, 2)

        this.goodSpecstableData = newResult2
      } else {

        newResult.forEach((el, idx) => {
          let obj = {
            product_id_add: idx,
            product_bn: '',
            product_attr: '',
            product_is_def: 1,
            product_image: '',
            product_price: '',
            product_cost_price: '',
            product_market_price: '',
            product_stock: ''
          }
          let a = { ...obj, ...el }
          newResult2.push(a)
        })
        this.goodSpecstableData = newResult2
      }

      //   
      // 2
      this.goods_specs.ischooseModehow = false

    },
    // 批量 规格图
    async batchSetuploadspecFiles(info, record, flag) {
      // console.log(info)
      // console.log(record); 
      // console.log(this.goodSpecstableData);
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.goods_specs.product_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 列表 规格图
    // 规格图
    async uploadspecFiles(info, record, flag) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        if (flag) {
          this.goodSpecsSingletableData[0].product_image = res.data.url
        } else {
          this.goodSpecstableData.forEach(el => {

            if (record.product_id >= 0) {
              if (el.product_id == record.product_id) {
                el.product_image = res.data.url
              }
            }
            if (record.product_id_add >= 0) {
              if (el.product_id_add == record.product_id_add) {
                el.product_image = res.data.url
              }
            }

          })
        }


        // this.permissionInfo.info.goods_image = res.data.url
        console.log(this.goodSpecstableData);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.permissionInfo.info.goods_slider && this.permissionInfo.info.goods_slider.length > 0) {

      } else {
        this.permissionInfo.info.goods_slider = []
      }
      this.permissionInfo.info.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.permissionInfo.info.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.permissionInfo.info.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.permissionInfo.info.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 提交按钮
    okclickmod(e) {

      function fn(beforeData, addorupdateSpecFlag) {
        var afterData = []
        beforeData.forEach(item => {
          let flag
          if (addorupdateSpecFlag == 'add') {
            flag = afterData.find(item1 => item1.product_id === item.product_id)
          } else {
            flag = afterData.find(item1 => item1.product_id_add === item.product_id_add)
          }

          if (!flag) {
            if (addorupdateSpecFlag == 'add') {
              afterData.push({
                product_id: item.product_id,
                product_attr: [item]
              })
            } else {
              afterData.push({
                product_id_add: item.product_id_add,
                product_attr: [item]
              })
            }

          } else {
            flag.product_attr.push(item)
          }
        })
        console.log(afterData, 'afterDataafterData');
        return afterData

      }
      function chkstrlen(str) {
        var strlen = false;
        for (var i = 0; i < str.length; i++) {
          if (str.charCodeAt(i) > 255) //如果是汉字， 
            strlen = true;
        }
        return strlen;
      }
      // 这里要搞事情 goods_specs.isSingle  生成多规格商品列表 goodSpecstableData
      if (this.goods_specs.isSingle !== 0) {
        this.permissionInfo.info.goods_attr_val = this.goodSpecstableData
        let arry = []
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          this.permissionInfo.info.goods_attr.forEach((m, n) => {
            let obj = {}
            if (el.hasOwnProperty(m.attr_name)) {
              //   
              obj[m.attr_name] = el[m.attr_name]
              obj.product_id = el.product_id
              obj.product_id_add = el.product_id_add
              arry.push(obj)
            }
          })
        })
        //   
        console.log(arry, "mainarr....");
        // arry=[ {"颜色":"白色"}， {"颜色":"黑色"}，{"尺寸":"m"}，{"尺寸":"l"}  ]
        let mainarr = fn(arry, this.addorupdateSpecFlag)
        mainarr.forEach(m => {
          let s = JSON.stringify(m.product_attr)
          m.product_attr = JSON.parse(s.replace(/},{/g, ","))
        })
        //   
        console.log(mainarr, "mainarr....");
        this.permissionInfo.info.goods_attr_val.forEach(el => {
          mainarr.forEach((m, n) => {
            if (el.product_id == m.product_id || el.product_id_add == m.product_id_add) {
              el.product_attr = m.product_attr[0]
              for (let item in el.product_attr) {
                let names = `"${item}"`
                el.product_attr[names] = el.product_attr[item]
                delete el.product_attr[item]
              }
            }
          })
        })

        console.log(this.permissionInfo.info.goods_attr_val, " this.permissionInfo.info.goods_attr_val");
        this.permissionInfo.info.goods_attr_val.forEach((el, idx) => {
          delete el.product_id
          delete el.product_id_add
          delete el.product_attr['"product_id"']
          delete el.product_attr['"product_id_add"']
          delete el.product_attr['"created_at"']
          delete el.product_attr['"updated_at"']
          delete el.created_at
          delete el.updated_at
          for (var pl in el) { //数组对象遍历
            console.log(pl); //获取key
            console.log(el[pl]) //获取key的值
            let flag = chkstrlen(pl) //判断是否中文字符
            if (flag) {
              delete el[pl]
            }
          }
        })
        //   
        console.log(this.permissionInfo.info.goods_attr_val);

      } else {
        this.permissionInfo.info.goods_attr = []
        delete this.goodSpecsSingletableData[0].product_id
        delete this.goodSpecsSingletableData[0].product_id_add
        this.permissionInfo.info.goods_attr_val = this.goodSpecsSingletableData
      }
      delete this.permissionInfo.info.pic_url_loading

      // 区分新增 修改接口
      if (this.permissionInfo.type == 3) {
        delete this.permissionInfo.info.created_at
        delete this.permissionInfo.info.updated_at
        let data = {
          ...this.permissionInfo.info
        }

        UpdateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        })
      } else {
        //新增


        let data = {
          ...this.permissionInfo.info
        }
        delete data.product_id
        delete data.product_id_add
        CreateGoods(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.permissionInfo.visible = false
            this.getList()
          }
        }).catch(err => {
          console.log(err);
        })
      }
    },
    handleClose(removedTag) {
      const tags = this.singelguigeadd.attr_values.filter(tag => tag !== removedTag);
      console.log(tags);
      this.singelguigeadd.attr_values = tags;
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },

    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      let tags = this.singelguigeadd.attr_values;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      console.log(tags);
      Object.assign(this, {
        inputVisible: false,
        inputValue: '',
      });
      this.singelguigeadd.attr_values = tags
    },

  },
  watch: {
    new_goods_specs_list: {
      handler: function (val) {
        console.log("监听3")

        console.log(val)
        let arr = []
        val.forEach(el => {
          this.goods_specs.goods_specs_list.forEach((m, n) => {
            if (el.attr_name == m.attr_name) {
              //  
              this.goods_specs.goods_specs_list.splice(n, 1)
            }

          })

        });
        console.log(this.goods_specs.goods_specs_list, "this.goods_specs.goods_specs_list");
        // this.isAllLength = arr.length
      },
      immediate: true,  // --首次加载时执行监听
      deep: true		  // --值改变时执行监听
    }

  }
};
</script>
<style lang="less" scoped>
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.baseColor11 {
  color: @baseColor11;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  // margin-top: -28px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  width: 300px;
  height: 74px;
  border-radius: 8px;
  border: 1px dashed #c8d6d6;
  text-align: center;
  line-height: 74px;
  cursor: pointer;
  i {
    color: #2d3835;
  }
  &:hover {
    color: @primaryColor;
    border: 1px dashed @primaryColor;
    i {
      color: @primaryColor;
    }
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: #2d3835;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: #2d3835;
      margin-right: 10px;
      width: 64px;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
}
.goods_specx_add {
  position: relative;
  display: inline-block;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 60px !important;
  height: 60px !important;
}
.meiye-shanchu {
  &:hover {
    background: #f0f5f4;
    border-radius: 4px;
  }
}
.meiye-close-bold {
  font-size: 12px;
  margin-top: 2px;
}
.meiye-routeicon-add-copy {
  font-size: 12px;
}
/deep/ .ant-tag {
  height: 24px;
  line-height: 22px;
  font-size: 14px;
}
/deep/ .ant-input_1.ant-input-sm {
  height: 24px;
  padding-bottom: 4px;
  padding-top: 2px;
  // line-height: 24px;
  // margin-top:  2px;
}
.tagsValue {
  position: relative;
  padding: 1px 8px;
  margin-right: 16px;
  background: #f3fafa;
  border-radius: 2px;
  .tagsValueTxt {
    margin: 0 8px 0 0;
    font-size: 14px;
    color: @baseColor11;
  }
  .meiye-close-bold {
    color: #578380;
  }
}
.editTags {
  /deep/.ant-tag {
    background: #f3fafa;
    border-radius: 2px;
    border: none;
    color: @baseColor11;
    .anticon-close {
      color: #578380;
    }
  }
  /deep/.ant-tag:nth-last-child(1) {
    border: 1px solid #d9d9d9;
    background: #f8f8f8;
    border-radius: 2px;
    color: #768483;
    cursor: pointer;
    .anticon-plus {
      color: #778f8e;
    }
  }
}
.tagsValue[data-v-248fbb06] {
  position: relative;
  padding: 1px 8px;
  margin-right: 16px;
  background: #f3fafa;
  border-radius: 2px;
  font-size: 14px;
  border: none;
  color: @baseColor11;
  .anticon-close {
    color: #578380;
  }
}
.ant-tag:nth-last-child(1) {
  border: 1px solid #d9d9d9;
  background: #f8f8f8;
  border-radius: 2px;
  color: #768483;
  cursor: pointer;
}
</style>